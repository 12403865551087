import { useState } from "react";
import { IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";
import ConfirmationDialog from "./ConfirmationDialog"; // Импортируем универсальный компонент

const DeleteButton = ({ onDelete, itemId ,disabled=false}) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleConfirm = () => {
        onDelete(itemId);
        handleClose();
    };

    return (
        <>
            <IconButton color="error" onClick={handleOpen} disabled={disabled}>
                <Delete />
            </IconButton>

            <ConfirmationDialog
                open={open}
                onClose={handleClose}
                onConfirm={handleConfirm}
                title="Подтверждение удаления"
                description="Вы уверены, что хотите удалить этот объект?"
                confirmText="Удалить"
            />
        </>
    );
};

export default DeleteButton;
