import React, { useEffect, useState } from "react";
import {
    Box,
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    IconButton,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Add, Edit, Delete, CheckCircle, Block } from "@mui/icons-material";
import api from "../api";
import { useNotification } from "../NotificationContext";
import { useLanguage } from "../LanguageContext";
import theme from "../theme";
import DeleteButton from "../components/DeleteButton";

export default function SeasonManager() {
    const [seasons, setSeasons] = useState([]);
    const [pageSize, setPageSize] = useState(5);
    const [newSeasonName, setNewSeasonName] = useState("");
    const [currentSeason, setCurrentSeason] = useState(null);
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [isAddMode, setAddMode] = useState(true);
    const [searchText, setSearchText] = useState("");
    const [filteredSeasons, setFilteredSeasons] = useState([]);

    const notify = useNotification();
    const { t } = useLanguage();

    useEffect(() => {
        fetchSeasons();
    }, []);

    useEffect(() => {
        const filtered = seasons.filter((season) =>
            season.name.toLowerCase().includes(searchText.toLowerCase())
        );
        setFilteredSeasons(filtered);
    }, [searchText, seasons]);

    const fetchSeasons = async () => {
        try {
            const { data } = await api.get("/api/seasons");
            setSeasons(data || []);
        } catch (err) {
            notify(t("error_fetching_seasons"), "error");
        }
    };

    const handleSaveSeason = async () => {
        if (!newSeasonName.trim()) {
            notify(t("season_name_required"), "error");
            return;
        }

        try {
            if (isAddMode) {
                const { data } = await api.post("/api/seasons", { name: newSeasonName });
                setSeasons((prev) => [...prev, data]);
                notify(t("season_added_successfully"), "success");
            } else {
                await api.put(`/api/seasons/${currentSeason.id}`, { name: newSeasonName });
                setSeasons((prev) =>
                    prev.map((season) =>
                        season.id === currentSeason.id ? { ...season, ...currentSeason } : season
                    )
                );
                notify(t("season_updated_successfully"), "success");
            }
            fetchSeasons();
            setDialogOpen(false);
            setNewSeasonName("");
        } catch (err) {
            notify(t("error_saving_season"), "error");
        }
    };

    const handleDeleteSeason = async (id) => {
        try {
            await api.delete(`/api/seasons/${id}`);
            setSeasons((prev) => prev.filter((season) => season.id !== id));
            notify(t("season_deleted_successfully"), "success");
        } catch (err) {
            notify(t("error_deleting"), "error");
        }
    };

    const handleSetCurrentSeason = async (id) => {
        try {
            await api.put(`/api/seasons/${id}/current`);
            setSeasons((prev) =>
                prev.map((season) =>
                    season.id === id ? { ...season, is_current: true } : { ...season, is_current: false }
                )
            );
            notify(t("season_set_as_current"), "success");
        } catch (err) {
            notify(t("error_setting_current_season"), "error");
        }
    };

    const handleOpenDialog = (season = null) => {
        if (season) {
            setCurrentSeason(season);
            setNewSeasonName(season.name);
            setAddMode(false);
        } else {
            setCurrentSeason(null);
            setNewSeasonName("");
            setAddMode(true);
        }
        setDialogOpen(true);
    };

    const columns = [
        { field: "name", headerName: t("season_name"), flex: 1 },
        {
            field: "actions",
            headerName: t("actions"),
            sortable: false,
            width: 200,
            renderCell: (params) => (
                <Box>
                    <IconButton
                        color="primary"
                        onClick={() => handleOpenDialog(params.row)}
                    >
                        <Edit />
                    </IconButton>

                    <DeleteButton onDelete={handleDeleteSeason} itemId={params.row.id} disabled={params.row.is_current}/>

                    <IconButton
                        color={params.row.is_current ? "success" : "warning"}
                        onClick={() => handleSetCurrentSeason(params.row.id)}
                        disabled={params.row.is_current}
                    >
                        {params.row.is_current ? <CheckCircle /> : <Block />}
                    </IconButton>
                </Box>
            ),
        },
    ];

    return (
        <Box p={3}>
            <Typography variant="h4" gutterBottom>
                {t("seasons")}
            </Typography>

            <Box display="flex" justifyContent="space-between" mb={2}>
                <TextField
                    label={t("search")}
                    variant="outlined"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    sx={{ flex: 1, mr: 2 }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Add />}
                    onClick={() => handleOpenDialog()}
                    sx={{ backgroundColor: theme.palette.menu.main,
                        '&:hover': {
                            backgroundColor: theme.palette.menu.inner,  // Пример светлее на 90%
                        }, }}
                >
                    {t("add")}
                </Button>
            </Box>

            <DataGrid
                rows={filteredSeasons}
                columns={columns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 20]}
                slotProps={{
                    pagination: {
                        labelRowsPerPage: t("rows_per_page"),
                        labelDisplayedRows: ({ from, to, count }) => `${from}–${to} ${t("of")} ${count}`, } }}
            />

            <Dialog open={isDialogOpen} onClose={() => setDialogOpen(false)}>
                <DialogTitle>
                    {isAddMode ? t("add") : t("edit")}
                </DialogTitle>
                <DialogContent>
                    <TextField
                        label={t("season_name")}
                        fullWidth
                        value={newSeasonName}
                        onChange={(e) => setNewSeasonName(e.target.value)}
                        variant="outlined"
                        margin="dense"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogOpen(false)} color="secondary">
                        {t("cancel")}
                    </Button>
                    <Button onClick={handleSaveSeason} color="primary">
                        {t("save")}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
