import React, {useEffect, useRef, useState} from "react";
import {
    Box,
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    IconButton,
    FormControlLabel,
    Switch,
    Chip,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Checkbox,
    CircularProgress,
    MenuList, Paper, Popper,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {Add, Edit, Delete, Download} from "@mui/icons-material";
import api from "../api";
import { useNotification } from "../NotificationContext";
import { useLanguage } from "../LanguageContext";
import axios from "axios";
import theme from "../theme";
import {LinkWrapper} from "../components/LinkWrapper";
import DeleteButton from "../components/DeleteButton";

export default function VotingObjectManager() {
    const [votingObjects, setVotingObjects] = useState([]);
    const [pageSize, setPageSize] = useState(5);
    const [currentVotingObject, setCurrentVotingObject] = useState(null);
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [isAddMode, setAddMode] = useState(true);
    const [searchText, setSearchText] = useState("");
    const [filteredObjects, setFilteredObjects] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const anchorRef = useRef(null);
    const [inputValues, setInputValues] = useState({});

    const handleArrayChangeChip = (key, value) => {
        // Обновляем локальное состояние для текстового поля
        setInputValues((prev) => ({ ...prev, [key]: value }));
        console.log(key,value)
        if (key=="social"){

            setNewVotingObject((prev) => ({ ...prev, [key]: value }));
        } else {
            const array = value.split(",").map((item) => item.trim()).filter((item) => item);
            setNewVotingObject((prev) => ({ ...prev, [key]: array }));
        }
        // Преобразуем строку в массив

    };

    const handleLoadVotingObjects = async () => {
        try {
            setLoading(true)
            const spreadsheetID = '1iby-Unu-7JYBifGQ3FcANcELJ0MBh-E16ovAunV6lUg';
            await api.get(`/api/process-sheet?spreadsheet_id=${spreadsheetID}`);
            fetchVotingObjects()

        } catch (error) {
            console.error('Ошибка при вызове API:', error);
        } finally {
            setLoading(false)
        }
    };

    const handleChipDeleteChip = (key, index) => {
        // Удаляем элемент из массива
        const updatedArray = [...(newVotingObject[key] || [])];
        updatedArray.splice(index, 1);

        // Обновляем текстовое поле и массив
        setInputValues((prev) => ({ ...prev, [key]: updatedArray.join(", ") }));
        setNewVotingObject((prev) => ({ ...prev, [key]: updatedArray }));
    };

    const handleINNChange = async (e) => {
        const value = e.target.value;
        setSearchTerm(value);
        handleChange("INN", value);

        if (value.length >= 10) { // Проверка длины для вызова API
            setLoading(true);
            try {
                const response = await axios.get(`https://egrul.itsoft.ru/short_data/?${value}`);
                console.log("API Response:", response.data);

                // Если данные возвращаются как объект, сразу заполняем поля
                let result = []
                const data = response.data || {};
                if (data.code === 2) { // Если код ответа 2 означает успех
                    const result = []; // Создаем новый массив
                    result.push(data); // Добавляем объект в массив
                    setOptions([...result]); // Устанавливаем массив в options
                    console.log("Options:", [...result]);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        }
    };

    const handleOptionSelect = (option) => {
        setNewVotingObject({
            ...newVotingObject,
            inn: option.inn,
            full_company_legal_name: option.chief,
            brand_name: option.short_name,
            registration_address: option.address,
        });
        setSearchTerm(option.inn);
        setOptions([]);
    };


    const nullableVotingObj= {
        category:[],
        company_name: "",
        full_company_legal_name: "",
        brand_name: "",
        inn: "",
        registration_address: "",
        year_of_foundation: null,
        city: "",
        website: "",
        social: "",
        logo: "",
        film_title: "",
        creators_of_the_film: "",
        director: "",
        producer: "",
        composer: "",
        other_creators: "",
        country_of_production: "",
        film_length: null,
        language: "",
        format: "",
        rightsholder: "",
        did_the_film_participate_in_festivals: "",
        short_bios_of_important_creators: "",
        film_synopsis: "",
        film_link: "",
        name: "",
        phone: "",
        email: "",
        upload_signed_agreement: "",
        data_processing_consent: false,
        confidentiality_policy: false,
        contest_conditions: false,
        unique_collection: false,
        third_party_rights: false,
        is_not_foreign_entity: false,
        is_not_government_funded_entity: false,
        order_field: "",
        media_coverage_of_brand: null,
        info_about_brand: "",
        brand_direction: "",
        selling_points: "",
        segment: "",
        company_participation_info: "",
        lookbook: "",
        designer: "",
        contact_person: "",
        moodboard: "",
        archives: [],
        media_coverages: [],
        illustrations: [],
        linesheet: "",
        price_range: "",
        production: "",
        collection: "",
        collection_category: "",
        previous_showcase: "",
        cookies: "",
        referer: "",
        form_id: "",
        sent: false,
        request_id: ""
    }
    const [newVotingObject, setNewVotingObject] = useState(nullableVotingObj);
    const [categories, setCategories] = useState([]);

    const notify = useNotification();
    const { t } = useLanguage();

    useEffect(() => {
        fetchVotingObjects();
        fetchCategories();
    }, []);

    useEffect(() => {
        const filtered = votingObjects.filter((obj) =>
            obj?.brand_name.toLowerCase().includes(searchText.toLowerCase())
        );
        setFilteredObjects(filtered);
    }, [searchText, votingObjects]);


    const fetchCategories = async () => {
        try {
            const { data } = await api.get("/api/categories");
            setCategories(data || []);
        } catch (err) {
            notify(t("error_fetching"), "error");
        }
    };

    const handleChange1 = (field, value) => {
        setNewVotingObject({
            ...newVotingObject,
            [field]: value,
        });
    };

    const fetchVotingObjects = async () => {
        try {
            const { data } = await api.get("/api/voting-objects");
            setVotingObjects(data || []);
        } catch (err) {
            notify(t("error_fetching_objects"), "error");
        }
    };

    const handleSaveObject = async () => {
        if (!newVotingObject.brand_name?.trim()) {
            notify(t("object_name_required"), "error");
            return;
        }
        console.log(newVotingObject);
        try {
            if (isAddMode) {
                const { data } = await api.post("/api/voting-objects", newVotingObject);
                console.log(data)
                setVotingObjects((prev) => [...prev, data]);
                notify(t("object_added_successfully"), "success");
            } else {
                await api.put(`/api/voting-objects/${currentVotingObject.id}`, newVotingObject);
                setVotingObjects((prev) =>
                    prev.map((obj) =>
                        obj.id === currentVotingObject.id
                            ? { ...obj, ...newVotingObject }
                            : obj
                    )
                );
                notify(t("object_updated_successfully"), "success");
            }
            fetchVotingObjects();
            setDialogOpen(false);
            setAddMode(true);
            setSearchTerm("");
            setNewVotingObject(nullableVotingObj);
        } catch (err) {
            notify(t("error_saving_object"), "error");
        }
    };

    const handleDeleteObject = async (id) => {
        try {
            await api.delete(`/api/voting-objects/${id}`);
            setVotingObjects((prev) => prev.filter((obj) => obj.id !== id));
            notify(t("object_deleted_successfully"), "success");
        } catch (err) {
            notify(t("error_deleting"), "error");
        }
    };

    const handleOpenDialog = (object = null) => {
        if (object) {
            let arr=[];
            Array.from(object.categories).forEach((item)=>{
                arr.push(item.id)
            });
            object.category=arr
            setSearchTerm(object.inn);
            const stringValueMb = (object.media_coverage_of_brand || []).join(", ");
            setInputValues((prev) => ({ ...prev, media_coverage_of_brand: stringValueMb }));

           // const stringValueS = (object.social|| []).join(", ");
          //  setInputValues((prev) => ({ ...prev, social: stringValueS }));

            const stringValueA = (object.archives|| []).join(", ");
            setInputValues((prev) => ({ ...prev, archives: stringValueA }));

            const stringValueI = (object.illustrations|| []).join(", ");
            setInputValues((prev) => ({ ...prev, illustrations: stringValueI }));

            const stringValueMC = (object.media_coverages|| []).join(", ");
            setInputValues((prev) => ({ ...prev, media_coverages: stringValueMC }));
            // object.media_coverage_of_brand=object.media_coverage_of_brand
            //     .join(",");
            //
            // object.socials=object.socials
            //     .join(",");

console.log(object)
            setCurrentVotingObject(object);
            setNewVotingObject(object);
            setAddMode(false);
        } else {
            setCurrentVotingObject(null);
            setAddMode(true);
            setNewVotingObject(nullableVotingObj);
        }
        setDialogOpen(true);
    };

    const handleChange = (field, value) => {
        setNewVotingObject({
            ...newVotingObject,
            [field]: value,
        });
    };

    const handleArrayChange = (field, value) => {
        setNewVotingObject({
            ...newVotingObject,
            [field]: value.split(",").map((item) => item.trim()),
        });
    };

    const handleChipDelete = (field, index) => {
        setNewVotingObject({
            ...newVotingObject,
            [field]: newVotingObject[field].filter((_, i) => i !== index),
        });
    };

    const columns = [
        { field: "brand_name", headerName: t("object_name"), flex: 1 },
        { field: "company_name", headerName: t("object_name"), flex: 1 },
        { field: "film_title", headerName: t("object_name"), flex: 1 },
        {
            field: "actions",
            headerName: t("actions"),
            sortable: false,
            width: 150,
            renderCell: (params) => (
                <Box>
                    <IconButton
                        color="primary"
                        onClick={() => handleOpenDialog(params.row)}
                    >
                        <Edit />
                    </IconButton>
                    <DeleteButton onDelete={handleDeleteObject} itemId={params.row.id} />
                </Box>
            ),
        },
    ];


    return (
        <Box p={3}>
            <Typography variant="h4" gutterBottom>
                {t("voting_objects")}
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Download />}
                    onClick={() => handleLoadVotingObjects()}
                    disabled={loading}
                    sx={{ backgroundColor: theme.palette.menu.main,
                        '&:hover': {
                            backgroundColor: theme.palette.menu.inner,  // Пример светлее на 90%
                        }, }}
                >
                    {t("load")}
                </Button>
            </Typography>

            <Box display="flex" justifyContent="space-between" mb={2}>
                <TextField
                    label={t("search")}
                    variant="outlined"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    sx={{ flex: 1, mr: 2 }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Add />}
                    onClick={() => handleOpenDialog()}
                    sx={{ backgroundColor: theme.palette.menu.main,
                        '&:hover': {
                            backgroundColor: theme.palette.menu.inner,  // Пример светлее на 90%
                        }, }}
                >
                    {t("add")}
                </Button>
            </Box>

            <DataGrid
                rows={filteredObjects}
                columns={columns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 20]}
                slotProps={{
                    pagination: {
                        labelRowsPerPage: t("rows_per_page"),
                        labelDisplayedRows: ({ from, to, count }) => `${from}–${to} ${t("of")} ${count}`, } }}
            />

            <Dialog open={isDialogOpen} onClose={() => setDialogOpen(false)}>
                <DialogTitle>
                    {isAddMode ? t("add") : t("edit")}
                </DialogTitle>
                <DialogContent>
                    <Box mb={2}>
                        <FormControl fullWidth variant="outlined" margin="dense">
                            <InputLabel>{t("category")}</InputLabel>
                            <Select
                                label={t("category")}
                                multiple
                                value={newVotingObject.category || []}
                                onChange={(e) => handleChange1("category", e.target.value)}
                                renderValue={(selected) =>
                                    categories.filter(cat => selected.includes(cat.id)).map(cat => cat.title).join(", ")
                                }
                            >
                                {categories.map((category) => (
                                    <MenuItem key={category.id} value={category.id}>
                                        <Checkbox checked={newVotingObject.category?.includes(category.id)} />
                                        {category.title}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <TextField
                        label={t("INN")}
                        fullWidth
                        value={searchTerm}
                        onChange={handleINNChange}
                        variant="outlined"
                        margin="dense"
                        InputProps={{
                            endAdornment: loading && <CircularProgress size={20} />,
                        }}
                        inputRef={anchorRef} // Привязка рефа
                    />
                    <Popper
                        open={options.length > 0}
                        anchorEl={anchorRef.current}
                        placement="bottom-start"
                        style={{ zIndex: 1300 }}
                    >
                        <Paper>
                            <MenuList>
                                {options?.map((option, index) => (
                                    <MenuItem key={index} onClick={() => handleOptionSelect(option)}>
                                        {option.inn} - {option.short_name}
                                    </MenuItem>
                                ))}
                            </MenuList>
                        </Paper>
                    </Popper>
                    <TextField
                        label={t("object_name")}
                        fullWidth
                        value={newVotingObject.brand_name || ""}
                        onChange={(e) =>
                            setNewVotingObject({
                                ...newVotingObject,
                                brand_name: e.target.value,
                            })
                        }
                        variant="outlined"
                        margin="dense"
                    />
                    <TextField
                        label={t("company_name")}
                        fullWidth
                        value={newVotingObject.company_name}
                        onChange={(e) => handleChange("company_name", e.target.value)}
                        variant="outlined"
                        margin="dense"
                    />
                    <TextField
                        label={t("jur_lico")}
                        fullWidth
                        value={newVotingObject.full_company_legal_name}
                        onChange={(e) => handleChange("full_company_legal_name", e.target.value)}
                        variant="outlined"
                        margin="dense"
                    />

                    <TextField
                        label={t("adress_registration")}
                        fullWidth
                        value={newVotingObject.registration_address}
                        onChange={(e) => handleChange("registration_address", e.target.value)}
                        variant="outlined"
                        margin="dense"
                    />
                    <TextField
                        label={t("year_of_foundation")}
                        type="number"
                        fullWidth
                        value={newVotingObject.year_of_foundation || ""}
                        onChange={(e) =>
                            setNewVotingObject({
                                ...newVotingObject,
                                year_of_foundation: e.target.value ? parseInt(e.target.value, 10) : "",
                            })
                        }
                        variant="outlined"
                        margin="dense"
                    />
                    <TextField
                        label={t("city")}
                        fullWidth
                        value={newVotingObject.city}
                        onChange={(e) => handleChange("city", e.target.value)}
                        variant="outlined"
                        margin="dense"
                    />
                    <TextField
                        label={t("collection")}
                        fullWidth
                        value={newVotingObject.collection}
                        onChange={(e) => handleChange("collection", e.target.value)}
                        variant="outlined"
                        margin="dense"
                    />
                    <TextField
                        label={t("collection_category")}
                        fullWidth
                        value={newVotingObject.collection_category}
                        onChange={(e) => handleChange("collection_category", e.target.value)}
                        variant="outlined"
                        margin="dense"
                    />
                    <Box mb={2}>

                        <TextField
                            label={t("media_coverage_of_brand")} // Локализованный текст
                            fullWidth
                            placeholder={t("enter_comma_separated")}
                            value={inputValues["media_coverage_of_brand"] || ""}
                            onChange={(e) => handleArrayChangeChip("media_coverage_of_brand", e.target.value)}
                            variant="outlined"
                            margin="dense"
                        />
                        <Box mt={1}>
                            {(newVotingObject.media_coverage_of_brand || []).map((item, index) => (
                                <Chip
                                    key={index}
                                    label={item}
                                    onDelete={() => handleChipDeleteChip("media_coverage_of_brand", index)}
                                    style={{ margin: "2px" }}
                                />
                            ))}
                        </Box>
                    </Box>
                    <TextField
                        label={t("info_about_brand")}
                        fullWidth
                        value={newVotingObject.info_about_brand || ""}
                        onChange={(e) => handleChange("info_about_brand", e.target.value)}
                        variant="outlined"
                        margin="dense"
                        multiline // Включает режим текстовой области
                        rows={4} // Задаёт количество строк (настраивается по вашему усмотрению)
                    />
                    <TextField
                        label={t("previous_showcase")}
                        fullWidth
                        value={newVotingObject.previous_showcase || ""}
                        onChange={(e) => handleChange("previous_showcase", e.target.value)}
                        variant="outlined"
                        margin="dense"
                        multiline // Включает режим текстовой области
                        rows={4} // Задаёт количество строк (настраивается по вашему усмотрению)
                    />
                    <TextField
                        label={t("website")}
                        fullWidth
                        value={newVotingObject.website}
                        onChange={(e) => handleChange("website", e.target.value)}
                        variant="outlined"
                        margin="dense"
                    />
                    {/*<Box mb={2}>*/}

                    {/*    <TextField*/}
                    {/*        label={t("socials")} // Локализованный текст*/}
                    {/*        fullWidth*/}
                    {/*        placeholder={t("enter_comma_separated")}*/}
                    {/*        value={inputValues["social"] || ""}*/}
                    {/*        onChange={(e) => handleArrayChangeChip("social", e.target.value)}*/}
                    {/*        variant="outlined"*/}
                    {/*        margin="dense"*/}
                    {/*    />*/}
                    {/*    <Box mt={1}>*/}
                    {/*        {(newVotingObject?.social || []).map((item, index) => (*/}
                    {/*            <Chip*/}
                    {/*                key={index}*/}
                    {/*                label={item}*/}
                    {/*                onDelete={() => handleChipDeleteChip("social", index)}*/}
                    {/*                style={{ margin: "2px" }}*/}
                    {/*            />*/}
                    {/*        ))}*/}
                    {/*    </Box>*/}
                    {/*</Box>*/}
                    <TextField
                        label={t("socials")}
                        fullWidth
                        value={newVotingObject.social}
                        onChange={(e) => handleChange("social", e.target.value)}
                        variant="outlined"
                        margin="dense"
                    />
                    <TextField
                        label={t("film_title")}
                        fullWidth
                        value={newVotingObject.film_title}
                        onChange={(e) => handleChange("film_title", e.target.value)}
                        variant="outlined"
                        margin="dense"
                    />
                    <TextField
                        label={t("logo")}
                        fullWidth
                        value={newVotingObject.logo}
                        onChange={(e) => handleChange("logo", e.target.value)}
                        variant="outlined"
                        margin="dense"
                    />
                    <TextField
                        label={t("creators_of_the_film")}
                        fullWidth
                        value={newVotingObject.creators_of_the_film || ""}
                        onChange={(e) => handleChange("creators_of_the_film", e.target.value)}
                        variant="outlined"
                        margin="dense"
                        multiline // Включает режим текстовой области
                        rows={4} // Задаёт количество строк (настраивается по вашему усмотрению)
                    />
                    <TextField
                        label={t("director")}
                        fullWidth
                        value={newVotingObject.director}
                        onChange={(e) => handleChange("director", e.target.value)}
                        variant="outlined"
                        margin="dense"
                    />
                    <TextField
                        label={t("producer")}
                        fullWidth
                        value={newVotingObject.producer}
                        onChange={(e) => handleChange("producer", e.target.value)}
                        variant="outlined"
                        margin="dense"
                    />
                    <TextField
                        label={t("composer")}
                        fullWidth
                        value={newVotingObject.composer}
                        onChange={(e) => handleChange("composer", e.target.value)}
                        variant="outlined"
                        margin="dense"
                    />
                    <TextField
                        label={t("other_creators")}
                        fullWidth
                        value={newVotingObject.other_creators || ""}
                        onChange={(e) => handleChange("other_creators", e.target.value)}
                        variant="outlined"
                        margin="dense"
                        multiline // Включает режим текстовой области
                        rows={4} // Задаёт количество строк (настраивается по вашему усмотрению)
                    />
                    <TextField
                        label={t("country_of_production")}
                        fullWidth
                        value={newVotingObject.country_of_production}
                        onChange={(e) => handleChange("country_of_production", e.target.value)}
                        variant="outlined"
                        margin="dense"
                    />
                    <TextField
                        label={t("film_length")}
                        type="number"
                        fullWidth
                        value={newVotingObject.film_length}
                        onChange={(e) => handleChange("film_length", parseInt(e.target.value, 10))}
                        variant="outlined"
                        margin="dense"
                    />
                    <TextField
                        label={t("language")}
                        fullWidth
                        value={newVotingObject.language}
                        onChange={(e) => handleChange("language", e.target.value)}
                        variant="outlined"
                        margin="dense"
                    />
                    <TextField
                        label={t("format")}
                        fullWidth
                        value={newVotingObject.format}
                        onChange={(e) => handleChange("format", e.target.value)}
                        variant="outlined"
                        margin="dense"
                    />
                    <TextField
                        label={t("rightsholder")}
                        fullWidth
                        value={newVotingObject.rightsholder}
                        onChange={(e) => handleChange("rightsholder", e.target.value)}
                        variant="outlined"
                        margin="dense"
                    />
                    <TextField
                        label={t("did_the_film_participate_in_festivals")}
                        fullWidth
                        value={newVotingObject.did_the_film_participate_in_festivals}
                        onChange={(e) => handleChange("did_the_film_participate_in_festivals", e.target.value)}
                        variant="outlined"
                        margin="dense"
                    />
                    <TextField
                        label={t("short_bios_of_important_creators")}
                        fullWidth
                        value={newVotingObject.short_bios_of_important_creators || ""}
                        onChange={(e) => handleChange("short_bios_of_important_creators", e.target.value)}
                        variant="outlined"
                        margin="dense"
                        multiline // Включает режим текстовой области
                        rows={4} // Задаёт количество строк (настраивается по вашему усмотрению)
                    />
                    <TextField
                        label={t("film_synopsis")}
                        fullWidth
                        value={newVotingObject.film_synopsis || ""}
                        onChange={(e) => handleChange("film_synopsis", e.target.value)}
                        variant="outlined"
                        margin="dense"
                        multiline // Включает режим текстовой области
                        rows={4} // Задаёт количество строк (настраивается по вашему усмотрению)
                    />
                    <TextField
                        label={t("film_link")}
                        fullWidth
                        value={newVotingObject.film_link}
                        onChange={(e) => handleChange("film_link", e.target.value)}
                        variant="outlined"
                        margin="dense"
                    />
                    <TextField
                        label={t("moodboard")}
                        fullWidth
                        value={newVotingObject.moodboard}
                        onChange={(e) => handleChange("moodboard", e.target.value)}
                        variant="outlined"
                        margin="dense"
                    />
                    <TextField
                        label={t("selling_points")}
                        fullWidth
                        value={newVotingObject.selling_points}
                        onChange={(e) => handleChange("selling_points", e.target.value)}
                        variant="outlined"
                        margin="dense"
                    />

                    <TextField
                        label={t("designer")}
                        fullWidth
                        value={newVotingObject.designer}
                        onChange={(e) => handleChange("designer", e.target.value)}
                        variant="outlined"
                        margin="dense"
                    />
                    <TextField
                        label={t("contact_person")}
                        fullWidth
                        value={newVotingObject.contact_person}
                        onChange={(e) => handleChange("contact_person", e.target.value)}
                        variant="outlined"
                        margin="dense"
                    />
                    <TextField
                        label={t("email")}
                        fullWidth
                        value={newVotingObject.email}
                        onChange={(e) => handleChange("email", e.target.value)}
                        variant="outlined"
                        margin="dense"
                    />
                    <TextField
                        label={t("phone")}
                        fullWidth
                        value={newVotingObject.phone}
                        onChange={(e) => handleChange("phone", e.target.value)}
                        variant="outlined"
                        margin="dense"
                    />
                    <TextField
                        label={t("upload_signed_agreement")}
                        fullWidth
                        value={newVotingObject.upload_signed_agreement}
                        onChange={(e) => handleChange("upload_signed_agreement", e.target.value)}
                        variant="outlined"
                        margin="dense"
                    />

                    <TextField
                        label={t("brand_direction")}
                        fullWidth
                        value={newVotingObject.brand_direction}
                        onChange={(e) => handleChange("brand_direction", e.target.value)}
                        variant="outlined"
                        margin="dense"
                    />
                    <TextField
                        label={t("segment")}
                        fullWidth
                        value={newVotingObject.segment}
                        onChange={(e) => handleChange("segment", e.target.value)}
                        variant="outlined"
                        margin="dense"
                    />
                    <TextField
                        label={t("company_participation_info")}
                        fullWidth
                        value={newVotingObject.company_participation_info}
                        onChange={(e) => handleChange("company_participation_info", e.target.value)}
                        variant="outlined"
                        margin="dense"
                    />
                    <TextField
                        label={t("lookbook")}
                        fullWidth
                        value={newVotingObject.lookbook}
                        onChange={(e) => handleChange("lookbook", e.target.value)}
                        variant="outlined"
                        margin="dense"
                    />
                    <TextField
                        label={t("linesheet")}
                        fullWidth
                        value={newVotingObject.linesheet}
                        onChange={(e) => handleChange("linesheet", e.target.value)}
                        variant="outlined"
                        margin="dense"
                    />
                    <TextField
                        label={t("price_range")}
                        fullWidth
                        value={newVotingObject.price_range}
                        onChange={(e) => handleChange("price_range", e.target.value)}
                        variant="outlined"
                        margin="dense"
                    />
                    <TextField
                        label={t("production")}
                        fullWidth
                        value={newVotingObject.production}
                        onChange={(e) => handleChange("production", e.target.value)}
                        variant="outlined"
                        margin="dense"
                    />
                    {/* Пример переключателей */}
                    <FormControlLabel
                        control={
                            <Switch
                                checked={newVotingObject.confidentiality_policy}
                                onChange={(e) => handleChange("confidentiality_policy", e.target.checked)}
                            />
                        }
                        label={t("confidentiality_policy")}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={newVotingObject.contest_conditions}
                                onChange={(e) => handleChange("contest_conditions", e.target.checked)}
                            />
                        }
                        label={t("contest_conditions")}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={newVotingObject.unique_collection}
                                onChange={(e) => handleChange("unique_collection", e.target.checked)}
                            />
                        }
                        label={t("unique_collection")}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={newVotingObject.obrabotka_dannykh}
                                onChange={(e) => handleChange("obrabotka_dannykh", e.target.checked)}
                            />
                        }
                        label={t("obrabotka_dannykh")}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={newVotingObject.third_party_rights}
                                onChange={(e) => handleChange("third_party_rights", e.target.checked)}
                            />
                        }
                        label={t("third_party_rights")}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={newVotingObject.is_not_foreign_entity}
                                onChange={(e) => handleChange("is_not_foreign_entity", e.target.checked)}
                            />
                        }
                        label={t("is_not_foreign_entity")}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={newVotingObject.is_not_government_funded_entity}
                                onChange={(e) => handleChange("is_not_government_funded_entity", e.target.checked)}
                            />
                        }
                        label={t("is_not_government_funded_entity")}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={newVotingObject.sent}
                                onChange={(e) => handleChange("sent", e.target.checked)}
                            />
                        }
                        label={t("sent")}
                    />

                    <TextField
                        label={t("media_coverages")} // Локализованный текст
                        fullWidth
                        placeholder={t("enter_comma_separated")}
                        value={inputValues["media_coverages"] || ""}
                        onChange={(e) => handleArrayChangeChip("media_coverages", e.target.value)}
                        variant="outlined"
                        margin="dense"
                    />
                    <Box mt={1}>
                        {(newVotingObject.media_coverages || []).map((item, index) => (
                            <Chip
                                key={index}
                                label={item}
                                onDelete={() => handleChipDeleteChip("media_coverages", index)}
                                style={{ margin: "2px" }}
                            />
                        ))}
                    </Box>

                    {/* Пример для archives */}

                    <TextField
                        label={t("archives")} // Локализованный текст
                        fullWidth
                        placeholder={t("enter_comma_separated")}
                        value={inputValues["archives"] || ""}
                        onChange={(e) => handleArrayChangeChip("archives", e.target.value)}
                        variant="outlined"
                        margin="dense"
                    />
                    <Box mt={1}>
                        {(newVotingObject.archives || []).map((item, index) => (
                            <Chip
                                key={index}
                                label={item}
                                onDelete={() => handleChipDeleteChip("archives", index)}
                                style={{ margin: "2px" }}
                            />
                        ))}
                    </Box>

                    {/* Пример для illustrations */}
                    <TextField
                        label={t("illustrations")} // Локализованный текст
                        fullWidth
                        placeholder={t("enter_comma_separated")}
                        value={inputValues["illustrations"] || ""}
                        onChange={(e) => handleArrayChangeChip("illustrations", e.target.value)}
                        variant="outlined"
                        margin="dense"
                    />
                    <Box mt={1}>
                        {(newVotingObject.illustrations || []).map((item, index) => (
                            <Chip
                                key={index}
                                label={item}
                                onDelete={() => handleChipDeleteChip("illustrations", index)}
                                style={{ margin: "2px" }}
                            />
                        ))}
                    </Box>
                    {/* Add additional fields here */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogOpen(false)} color="secondary">
                        {t("cancel")}
                    </Button>
                    <Button onClick={handleSaveObject} color="primary">
                        {t("save")}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
